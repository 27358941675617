import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import App from './App'

import './index.css'

import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import $config from "./config.json"

ReactDOM.render(
  // <App />,
  <Auth0Provider
    domain={$config.auth0.domain}
    clientId={$config.auth0.clientId}
    audience={$config.auth0.audience}
    redirectUri={window.location.origin}
    scope="openid"
  >
    <Router>
      <Switch>
        <Route path="/:paramid">
          <App />
        </Route>
        <Route path="">
          <App />
        </Route>
      </Switch>
    </Router>
  </Auth0Provider>,
  document.getElementById('root')
)

// serviceWorker.unregister()