import React, { Fragment, useState, useEffect, useContext } from "react"

import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from './Theme'
import { GlobalStyles } from './Global'

import './App.css'

import { useAuth0 } from "@auth0/auth0-react"
import Form from "./Form"
import 'bootstrap/dist/css/bootstrap.min.css'
import Image from 'react-bootstrap/Image'

function Profile({ isLoading, user }) {
  if (isLoading || !user) {
    return <span className="sr-only">Loading...</span>
  }

  return (
    <span className="profile">
      <Image src={user.picture} roundedCircle alt="Profile" />
      <span>{user.name}</span>
    </span>
  )
}



// const Profile = props => {
//   if (props.isLoading || !props.user) {
//     return (
//       <div>Loading...</div>
//     )
//   }

//   return (
//     <div>
//       <span>{props.user.name}</span>&nbsp;<img src={props.user.picture} alt="Profile" />
//     </div>
//   )
// }

function LoginButton({ isAuthenticated, loginWithRedirect }) {
  if (!isAuthenticated) {
    return (
      <a className="link" onClick={() => loginWithRedirect()}>
        Login
      </a>
    )
  }
  return null
}

function LogoutButton({ isAuthenticated, logout }) {
  if (isAuthenticated) {
    return (
      <a className="link" onClick={() => logout({ returnTo: window.location.origin })}>
        Logout
      </a>
    )
  }
  return null
}

const App = () => {
  const themeKey = "::theme"
  const { user, isAuthenticated, isLoading, logout, loginWithRedirect, getAccessTokenSilently } = useAuth0()
  const [theme, setTheme] = useState(localStorage.getItem(themeKey) || 'light')

  useEffect(() => {
    localStorage.setItem(themeKey, theme)
  }, [theme])

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark')
    } else {
      setTheme('light')
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <GlobalStyles /> <div className="App">
          <header>
            <div className="header">
              <div className="pure-g">
                <div className="pure-u-1-3">
                  <LoginButton isAuthenticated={isAuthenticated} loginWithRedirect={loginWithRedirect} />
                  {/* <a className="link" onClick={toggleTheme}>Theme</a> */}
                </div>
                <div className="pure-u-2-3 profile">
                  {isAuthenticated && <Profile user={user} isLoading={isLoading} />}
                  <LogoutButton isAuthenticated={isAuthenticated} logout={logout} />
                </div>
              </div>
            </div>
          </header>
          <div className="heading">
            <h1>
              {!isAuthenticated ? (<a href={() => { }} className="ninja" onClick={() => loginWithRedirect()}>L</a>) : <span>L</span>}ogical Topology Designer</h1>
            <p>Created by <a target="_blank" rel="noopener noreferrer" href="https://davidbetz.net">David Betz</a> | Related: <a target="_blank" rel="noopener noreferrer" href="https://github.com/davidbetz/virlconfig">VIRL/GNS3 Connector</a> &amp; <a target="_blank" rel="noopener noreferrer" href="https://labstudio.netfxharmonics.com/">Lab Studio</a></p>
            <p>Clean, deterministic logical topology for GNS3, VIRL, CML, etc.</p>
            <p>Usage: Type a topology. Links are inferred. Prefix with r, xr, nx, or s; use v for vlan.</p>
          </div>
          <div className="body">
            <Form user={user} isAuthenticated={isAuthenticated} getAccessTokenSilently={getAccessTokenSilently} />
          </div>
        </div>
      </>
    </ThemeProvider >
  )
}

export default App
