const Commands = {
        xr: {
                loopback: `
interface lo0
 ipv4 address {{ Id }}.{{ Id }}.{{ Id }}.{{ Id }} 255.255.255.255
 ipv6 address 2001:db8::{{ Id }}/128{`,
                stub: `
interface g0/0/0/0.{{ Id }}
 encapsulation dot1q {{ Id }}
 ipv4 address 172.16.{{ Segment }}.{{ Id }} 255.255.255.0
 ipv6 address 2001:db8:{{ Segment }}::{{ Id }}/64`,
                bareStub: "interface g0/0/0/0.{{ Id }}",
                bareInterface: "interface g0/0/0/0.{{ Subinterface }}",
                interface: `
interface g0/0/0/0.{{ Subinterface }}
 encapsulation dot1q {{ Vlan }}
 ipv4 address 10.{{ From }}.{{ To }}.{{ Current }} 255.255.255.0
 ipv6 address 2001:db8:{{ From }}:{{ To }}::{{ Current }}/64{{ OspfP2p }}`,
                enabler: `
interface g0/0/0/0
 no shut
 commit`,
                generic: `
no ip domain-lookup
line console
exec-timeout 0 0`,
        },
        r: {
                loopback: `
interface lo0
 ip address {{ Id }}.{{ Id }}.{{ Id }}.{{ Id }} 255.255.255.255
 ipv6 address 2001:db8::{{ Id }}/128{{ OspfArea0 }}`,
                stub: `
interface {{ Interface }}.{{ Id }}
 encapsulation dot1q {{ Id }}
 ip address 172.16.{{ Segment }}.{{ Id }} 255.255.255.0
 ipv6 address 2001:db8:{{ Segment }}::{{ Id }}/64{{ OspfArea0 }}`,
                bareStub: `interface {{ Interface }}.{{ Id }}`,
                bareInterface: `interface {{ Interface }}.{{ Subinterface }}`,
                interface: `
interface {{ Interface }}.{{ Subinterface }}
 encapsulation dot1q {{ Vlan }}
 ip address 10.{{ From }}.{{ To }}.{{ Current }} 255.255.255.0
 ipv6 address 2001:db8:{{ From }}:{{ To }}::{{ Current }}/64{{ OspfP2p }}{{ OspfArea0 }}`,
                enabler: `
interface {{ Interface }}
 no shut`,
                generic: `
no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0`,
        },
        s: {},
        nx: {}
}

export { Commands }