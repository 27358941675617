export const templates = [
    {
        id: 'intsp4standard',
        type: 'sample',
        name: 'ine.spv4.standard',
        topology: `' sample: INE SPv4 AdvTech
r6: r4,r5
r4: xr1,r5
r5: r1
xr1: r8,r1,xr2
r1: xr2,r2
xr2: xr3,r2
r2: xr3,r3,r10
xr3: xr4,r3,r7
r3: r7
r8: r9
r9: xr4`
    },
    {
        id: "inesp4csr",
        type: 'sample',
        name: "ine.spv4.csr",
        topology: `' sample: INE SPv4 AdvTech (CSR)
r6: r4,r5
r4: r11,r5
r5: r1
r11: r8,r1,r12
r1: r12,r2
r12: r13,r2
r2: r13,r3,r10
r13: r14,r3,r7
r3: r7
r8: r9
r9: r14`
    },
    {
        id: 'ineent',
        type: 'sample',
        name: 'ine.ent',
        topology: `' sample: INE CCIE Ent
r9:r7
r7:r3,r6
r6:v146
r1:v146,r5
r2:r5
r3:r5
r3:r5,r7
r4:v146,r5
r5:r8
r8:r10`
    },
    {
        id: 'switching3tier',
        type: 'sample',
        name: 'switching.3-tier',
        topology: `s1:s2,s3,s4,s5
s2:s3,s4,s5
s6:s3,s4,s5
s7:s3,s4,s5
s8:s3,s4,s5
g1:s1,s2
g2:s3,s4,s5
g3:s6,s7,s8`
    },
    {
        id: 'switching2tier',
        type: 'sample',
        name: 'switching.2-tier',
        topology: `s1:s2,s3,s4,s5
s2:s3,s4,s5
g1:s1,s2
g2:s3,s4,s5`
    },
    {
        id: 'sampler',
        type: 'sample',
        name: 'mixed.xr-csr-vlan',
        topology: `xr1:r1,r2,r3
xr2:r1,r2,r3
r3:r4,r5
r4:v100
r5:v100
r6:v100`
    },
    {
        id: 'samplersw',
        type: 'sample',
        name: 'mixed.xr-csr-vlan-nx-l2',
        topology: `xr1:r1,r2,r3
xr2:r1,r2,r3
r3:nx4,nx5
nx4:v100
nx5:v100
s6:v100`
    },
    {
        id: 'csrchain',
        type: 'sample',
        name: 'csr.chain',
        topology: `r1:r2
r2:r3
r3:r4
r4:r5`
    },
    {
        id: 'csrsquare',
        type: 'sample',
        name: 'csr.square',
        topology: `r1:r2,r4
r2:r3
r3:r4`
    },
    {
        id: 'csrtassles',
        type: 'sample',
        name: 'csr.tassles',
        topology: `r2:r1,r3,r5
r3:r1,r6
r1:r4`
    },
    {
        id: 'csrclos',
        type: 'sample',
        name: 'csr.clos',
        topology: `r1:r2,r3,r4
r5:r2,r3,r4
g1:r1,r5
g2:r2,r3,r4`
    },
    {
        id: 'csrenvelope',
        type: 'sample',
        name: 'csr.envelope',
        topology: `r1:r2,r3
r2:r3,r6
r4:r5,r6
r5:r6,r3
r1:r4`
    },
    {
        id: 'csrhexagon',
        type: 'sample',
        name: 'csr.hexagon',
        topology: `r1:r2,r6
r2:r3
r3:r4
r4:r5
r5:r6`
    },
    {
        id: 'csrfullmesh',
        type: 'sample',
        name: 'csr.fullmesh',
        topology: `r1:r2,r3,r4,r5,r6
r2:r3,r4,r5,r6
r3:r4,r5,r6
r4:r5,r6
r5:r6`
    },
    {
        id: 'csrtorus',
        type: 'sample',
        name: 'csr.torus',
        topology: `r1:r2,r3,r4,r7
r2:r3,r5,r8
r3:r6,r9
r4:r5,r6,r7
r5:r6,r8
r6:r9
r7:r8,r9
r8:r9`
    },
    {
        id: 'csrring',
        type: 'sample',
        name: 'csr.ring',
        topology: `r1:r2,r6
r2:r3
r3:r4
r4:r5
r5:r6`
    },
    {
        id: 'csrchicken',
        type: 'sample',
        name: 'csr.chicken',
        topology: `r1:r2,r4,r5
r2:r3,r5,r6
r3:r6,r7
r4:r5,r8
r5:r6,r8,r9
r6:r7,r9,r10
r7:r10
r8:r9,r11
r9:r10,r11,r12
r10:r12`
    },
    {
        id: 'csrstroller',
        type: 'sample',
        name: 'csr.stroller',
        topology: `r1:r2,r4,r5
r2:r3,r5,r6
r3:r6,r7
r4:r8,r5
r6:r7,r9,r10
r7:r10
r8:r9,r11
r9:r10,r11,r12
r10:r12`
    },
    {
        id: 'csrstingray',
        type: 'sample',
        name: 'csr.stingray',
        topology: `r1:r2,r4,r5,r12
r2:r3,r5,r6,r12
r3:r6,r7
r4:r5,r8
r5:r6,r8,r9
r6:r7,r9,r10
r7:r10
r8:r9,r11
r9:r10,r11`
    }, {
        id: 'csrladder',
        type: 'sample',
        name: 'csr.ladder',
        topology: `r4:r2,r6,r1
r2:r5,r1
r6:r8,r5
r5:r7
r8:r7,r3
r7:r3`},
    {
        id: 'csrball',
        type: 'sample',
        name: 'csr.ball',
        topology: `r1:r2,r6
r2:r3
r3:r4
r4:r5
r5:r6
r7:r1,r2,r3,r4,r5,r6
r8:r1,r2,r3,r4,r5,r6`
    },
    {
        id: 'csrmirrorstraight',
        type: 'sample',
        name: 'csr.mirror.straight',
        topology: `r1:r2,r3
r4:r5,r6
r2:r3
r5:r6
r1:r4
r2:r5
g1:r1,r2,r3
g2:r4,r5,r6`
    },
    {
        id: 'csrmirrorcrossed',
        type: 'sample',
        name: 'csr.mirror.crossed',
        topology: `r1:r2,r3
r4:r5,r6,r2
r2:r3
r5:r6,r1
r1:r4
r2:r5
g1:r1,r2,r3
g2:r4,r5,r6`
    },
    {
        id: 'csrtree',
        type: 'sample',
        name: 'csr.tree',
        topology: `r1:r2,r3,r7
r4:r5,r6,r7
r7:r8`
    },
    {
        id: 'csrtwin',
        type: 'sample',
        name: 'csr.twin',
        topology: `r1:r2,r5,r9
r2:r3
r3:r4
r4:r5
r7:r8,r5
r8:r9`
    },
    {
        id: 'csrtriangulated',
        type: 'sample',
        name: 'csr.triangulated',
        topology: `r1:r2,r3,r4,r5,r6
r2:r3
r3:r4
r4:r5
r5:r6
r6:r2
r7:r2,r3,r6`
    }
]
