import React, { Fragment, useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"

import CytoscapeComponent from 'react-cytoscapejs'
import cytoscape from 'cytoscape'
import cola from 'cytoscape-cola'
import config from "./config.json"

import Swal from 'sweetalert2'

import './App.css'
import 'purecss/build/pure.css'
import 'purecss/build/grids-responsive.css'

import { uuidv4 } from './Uuid'
import { templates } from './Templates'
import { parse, getNodeData, countDevices, countLinks } from './Topology'
import { generate } from './Config'
import { IosVersion } from './IosVersion'

cytoscape.use(cola)

const Form = ({ user, isAuthenticated, getAccessTokenSilently }) => {
  const addLabHelpsKey = "::addLabHelps"
  const addRestconfKey = "::addRestconf"
  const addOspfP2pKey = "::addOspfP2p"
  const addOspfArea0Key = "::addOspfArea0"
  const addStubKey = "::addStub"
  const addAAAKey = "::addAAA"
  const iosVersionKey = "::iosVersion"
  const addManagedSwitchConfigKey = "::addManagedSwitchConfig"

  // const [templateConfigValue, setTemplateConfigValue] = useState()
  const [topology, setTopology] = useState()
  const { paramid } = useParams()
  const [id, setID] = useState(paramid || "csrchain")
  // const [id, setID] = useState(localStorage.getItem(idKey) || '')

  const [parsedData, setParsedData] = useState('')
  const [showGraph, setShowGraph] = useState(false)
  //+ dirty means not saved to server
  const [dirty, setDirty] = useState(false)
  const [name, setName] = useState()
  const [customTemplates, setCustomTemplates] = useState()

  const [addLabHelps, setAddGeneric] = useState(JSON.parse(localStorage.getItem(addLabHelpsKey) || "false"))
  const [addRestconf, setAddRestconf] = useState(JSON.parse(localStorage.getItem(addRestconfKey) || "false"))
  const [addOspfP2p, setAddOspfP2p] = useState(JSON.parse(localStorage.getItem(addOspfP2pKey) || "false"))
  const [addOspfArea0, setAddOspfArea0] = useState(JSON.parse(localStorage.getItem(addOspfArea0Key) || "false"))
  const [addStub, setAddStub] = useState(JSON.parse(localStorage.getItem(addStubKey) || "false"))
  const [addAAA, setAddAAA] = useState(JSON.parse(localStorage.getItem(addAAAKey) || "false"))
  const [iosVersion, setIosVersion] = useState(localStorage.getItem(iosVersionKey) || 'g2')
  const [addManagedSwitchConfig, setAddManagedSwitchConfig] = useState(JSON.parse(localStorage.getItem(addManagedSwitchConfigKey) || "false"))

  const [elements, setElements] = useState({})
  const [layout, setLayout] = useState({ name: 'cola', animate: false })
  const [graphStyle, setGraphStyle] = useState({ width: '500px', height: '400px' })
  const [cy, setCy] = useState(0)
  let history = useHistory()

  // useEffect(() => {
  //   console.log("check", "use effect", "no param")
  // })

  useEffect(() => {
    console.log("check", "use effect", "[cy]")
    if (cy) {
      cy.on('tap', 'node', function (evt) {
        var node = evt.target
        console.log('tapped node ' + node.id())
      })
      cy.on('tap', 'edge', function (evt) {
        var node = evt.target
        console.log('tapped edge ' + node.id())
      })
      cy.on('tap', function (event) {
        var evtTarget = event.target
        if (evtTarget === cy) {
          console.log('tap on background')
        }
      })
    }
  }, [cy])

  // useEffect(() => {
  //   (async () => {
  //     console.log("use effect", "[]")
  //     if (isAuthenticated) {
  //       // await loadTopologies()
  //       await loadTopology()
  //     }
  //   })()
  // }, [])

  useEffect(() => {
    console.log("use effect", "[id]")
    loadTopology()
    let item
    if (customTemplates) {
      item = customTemplates.find(p => p.id == id)
    }
    if (!item) {
      item = templates.find(p => p.id == id)
    }
    history.push("/" + id)
    if (item) {
      document.title = 'Logical Topology Designer - ' + item.name
    }
  }, [id])

  // useEffect(() => {
  //   console.log("use effect", "[name, topology]")
  //   setDirty(true)
  // }, [name])

  // useEffect(() => {
  //   (async () => {
  //     if (id === "2") {
  //       setTopology('')
  //       return
  //     }
  //     if (!id || id === "-1" || id === "0" || id === "1") {
  //       return
  //     }
  //     let item
  //     console.log("loadTopology", "id", id)
  //     if (isCustom() === true) {
  //       item = await loadTopology(id)
  //     }
  //     else {
  //       item = templates.find(p => p.id === id)
  //     }
  //     console.log("loadTopology", "item", item)
  //     if (item) {
  //       setTemplateConfigValue(id)
  //       setTopology(item.topology)
  //       setDirty(false)
  //     }
  //     else {
  //       console.log("loadTopology", "NOT FOUND", id)
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Not found.',
  //         text: 'Topology not found.'
  //       })
  //     }
  //   })()
  // }, [id])

  useEffect(() => {
    console.log("use effect", "[topology]")
    setShowGraph(false)
    let parsedData
    if (topology && topology.length > 0) {
      parsedData = parse(topology)
    }
    setParsedData(parsedData)
    // localStorage.setItem(templateConfigValueKey, templateConfigValue)
    // localStorage.setItem(topologyKey, topology)
  }, [topology])

  useEffect(() => {
    console.log("use effect", "[parsedData, addLabHelps, addRestconf, addOspfP2p, addOspfArea0, addStub, addAAA, iosVersion, addManagedSwitchConfig]")
    localStorage.setItem(addLabHelpsKey, addLabHelps)
    localStorage.setItem(addRestconfKey, addRestconf)
    localStorage.setItem(addOspfP2pKey, addOspfP2p)
    localStorage.setItem(addOspfArea0Key, addOspfArea0)
    localStorage.setItem(addStubKey, addStub)
    localStorage.setItem(addAAAKey, addAAA)
    localStorage.setItem(iosVersionKey, iosVersion)
    localStorage.setItem(addManagedSwitchConfigKey, addManagedSwitchConfig)
    if (parsedData) {
      updateGraph()
    }
  }, [parsedData, addLabHelps, addRestconf, addOspfP2p, addOspfArea0, addStub, addAAA, iosVersion, addManagedSwitchConfig])


  // document.addEventListener("keydown", keydown, false)
  // return () => {
  //   // document.removeEventListener("keydown", escFunction, false)
  // }

  // const getAccessTokenSilently = props.getAccessTokenSilently

  // function categorize(input) {
  //   function decorate($data) {
  //     $data = $data.sort((a, b) => (a["name"] > b["name"]) ? 1 : -1)
  //     for (let item of $data) {
  //       const parts = item.name.split(".")
  //       parts.shift()
  //       item.subname = parts.join(".")
  //     }
  //     return $data
  //   }

  //   function groupBy($data, prop = "name") {
  //     const envelopes = []
  //     for (const item of $data) {
  //       let envelope = { item }
  //       envelope.category = item[prop].split(".")[0]
  //       envelopes.push(envelope)
  //     }
  //     return envelopes.reduce((acc, curr) => {
  //       if (!acc[curr.category]) acc[curr.category] = []
  //       acc[curr.category].push(curr)
  //       return acc
  //     }, {})
  //   }
  //   const grouped = groupBy(decorate(input))
  //   const output = []
  //   for (const groupName in grouped) {
  //     console.log(groupName)
  //     const subGrouped = groupBy(grouped[groupName].map(p => p.item), "subname")
  //     for (const subGroupName in subGrouped) {
  //       output.push({ title: subGroupName })
  //       for (const asdf in subGrouped[subGroupName]) {
  //         console.log(subGrouped[subGroupName][asdf].item)
  //         const item = subGrouped[subGroupName][asdf].item
  //         output.push({ title: " " + item.subname, item })
  //       }
  //     }
  //   }
  //   return output
  // }

  function keydown(event) {
    if (event.shiftKey === true && event.keyCode === 221) {
      this.startDemo()
    }
  }

  function isCustom() {
    return id.includes("-")
  }

  function getInputClassName() {
    return "input"
    // return "input" + (dirty ? " dirty" : "")
  }

  // startDemo() {
  //   let index = 0
  //   const INTERVAL = 1000
  //   const demo = this.samples.find(p => p.id === '_sampler').topology
  //   const lines = demo.split('\n')
  //   this.pending = ''
  //   const run = () => {
  //     if (index < lines.length) {
  //       this.handleChange({ target: { name: 'topology', value: this.state.topology + lines[index++] + '\n' } })
  //     }
  //     else {
  //       clearInterval(timer)
  //     }
  //   }
  //   let timer = setInterval(() => {
  //     run()
  //   }, INTERVAL)
  // }

  // load() {
  //   const data = await this.persistence.load()
  //   this.u = data
  // }

  function updateGraph() {
    // console.log("updateGraph", JSON.stringify(parsedData))
    const getColor = type => {
      switch (type) {
        case 'r': return '#1898cf'
        case 'xr': return '#44318d'
        case 'nx': return '#116466'
        case 's': return '#6a9955'
        case 'v': return '#ac3b61'
        default: //+ nothing
      }
    }
    const graphData = getNodeData(parsedData)
    // console.log("graphData", JSON.stringify(graphData))
    const { nodes, edges } = graphData
    // const groupId = uuidv4()
    // const group = {
    //   data: { id: groupId, label: "group" },
    //   style: { backgroundColor: getColor('#f00') }
    // }
    const nodeElements = nodes.map(p => {
      console.log("p.group", p.group)
      return {
        data: { parent: p.group, id: p.id, label: p.name.startsWith("g") ? "" : p.name },
        style: { backgroundColor: getColor(p.type) }
      }
    })
    // nodeElements.push(group)
    const edgeElements = edges.map(p => {
      return {
        data: { source: parseInt(p.id), target: parseInt(p.remote), label: p.vlan },
        style: {
          'width': 3,
          'line-color': p.type === 'v' ? '#edb5bf' : '#ccc',
          'target-arrow-color': '#ccc',
          'target-arrow-shape': 'triangle'
        }
      }
    })
    setElements(nodeElements.concat(edgeElements))
    setTimeout(() => {
      setShowGraph(true)
    }, 10)
  }

  function handleSubmit(event) {
    event.preventDefault()
  }

  // confirmLoad() {
  //   return new Promise((resolve, reject) => {
  //     resolve(true)
  //     // Swal.fire({
  //     //   title: 'Are you sure?',
  //     //   text: "You will lose active topology.",
  //     //   icon: 'warning',
  //     //   showCancelButton: true,
  //     //   confirmButtonColor: '#3085d6',
  //     //   cancelButtonColor: '#d33',
  //     //   confirmButtonText: 'Yes, load topology.'
  //     // }).then((result) => {
  //     //   resolve(result.value)
  //     // })
  //   })
  // }

  function exportConfig() {
    Swal.fire({
      text: "Export to Lab Studio?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Export`
    })
      .then(p => {
        if (p.isConfirmed === false) {
          console.log("Import cancelled.")
          return
        }
        console.log("exportConfig")
        // setID(uuidv4())
        const url = config.api_base + '/api/v1/labs'
        const $layers = generate(topology, parsedData, { addLabHelps, addRestconf, addOspfP2p, addOspfArea0, addStub, addAAA, iosVersion, addManagedSwitchConfig })
        getAccessTokenSilently()
          .then(token =>
            fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
              },
              body: JSON.stringify({ config: $layers })
            })
              .then(async response => {
                // console.log(response)
                const { id } = await response.json()
                if (response.status > 204) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Cannot export.',
                    text: 'Cannot export.'
                  })
                }
                else {
                  if (id) {
                    Swal.fire({
                      html: `<pre>Import successful.\n\nClick ID to open:\n<a href="https://labstudio.netfxharmonics.com/${id}">${id}</a></pre>`,
                    })
                  }
                }
              })
              .catch(err => {
                Swal.fire({
                  icon: 'error',
                  title: 'Cannot export.',
                  text: err
                })
              })
          )
      })
  }

  function newTopology() {
    console.log("newTopology")
    // setID(uuidv4())
    const url = config.api_base + '/api/v1/topology'
    getAccessTokenSilently()
      .then(token =>
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
          .then(async response => {
            // console.log(response)
            const { id } = await response.json()
            if (response.status > 204) {
              Swal.fire({
                icon: 'error',
                title: 'Cannot create.',
                text: 'Cannot create.'
              })
            }
            else {
              await loadTopologies()
              // console.log("~newTopology", "id", id)
              setID(id)
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Cannot create.',
              text: err
            })
          })
      )
  }

  function saveTopology() {
    console.log("saveTopology", id, name, topology)
    const url = config.api_base + '/api/v1/topology'
    getAccessTokenSilently()
      .then(token =>
        fetch(url + "/" + id, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({ name, topology })
        })
          .then(async response => {
            const responseObject = await response.json()
            // console.log("response", responseObject)
            if (response.status > 204) {
              Swal.fire({
                icon: 'error',
                title: 'Cannot save.',
                text: responseObject.message
              })
            } else {
              setTopology(responseObject.topology)
              await loadTopologies()
              setDirty(false)
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'success',
                title: 'Topology sanitized and saved'
              })
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Cannot save.',
              text: err
            })
          })
      )
  }

  function deleteTopology() {
    console.log("deleteTopology", id, name)

    Swal.fire({
      text: "Are you sure you want to delete this?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Delete ${name}?`
    })
      .then((result) => {
        if (result.isConfirmed) {
          const url = config.api_base + '/api/v1/topology'
          getAccessTokenSilently()
            .then(token =>
              fetch(url + "/" + id, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
                }
              })
                .then(async response => {
                  if (response.status !== 204) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Cannot delete.',
                      text: 'Cannot delete.'
                    })
                  } else {
                    Swal.fire('Deleted.')
                    // setTemplateConfigValue(id)
                    setTopology('')
                    setName('')
                    await loadTopologies()
                  }
                })
                .catch(err => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Cannot save.',
                    text: err
                  })
                })
            )
        }
      })
  }

  async function copyToClipboard(contents) {
    navigator.clipboard.writeText(contents)
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: 'success',
      title: 'Config copied to clipboard.'
    })
  }

  async function loadTopologies() {
    console.log("loadTopologies")
    if (!isAuthenticated) {
      return
    }
    const url = config.api_base + '/api/v1/topology'
    const token = await getAccessTokenSilently()
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      // console.log("response")
      const topologies = await response.json()
      // console.log("response", topologies)
      setCustomTemplates(topologies)
      // Swal.fire('Saved.')
    } catch (err) {
      console.error(err)
      Swal.fire({
        icon: 'error',
        title: 'Cannot load.',
        text: 'Error loading topologies.'
      })
    }
  }

  async function fetchTopology(id) {
    console.log("fetchTopology", id)
    if (!isAuthenticated) {
      return
    }
    const url = config.api_base + '/api/v1/topology'
    const token = await getAccessTokenSilently()
    try {
      const response = await fetch(url + "/" + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      const topology = await response.json()
      // console.log("response", topology)
      return topology
    }
    catch (err) {
      console.error(err)
      Swal.fire({
        icon: 'error',
        title: 'Cannot load.',
        text: 'Error loading topologies.'
      })
    }
  }

  function getGraphElement() {
    if (showGraph) {
      return (
        <CytoscapeComponent cy={cy1 => { setCy(cy1) }} elements={elements} layout={layout} style={graphStyle} />
      )
    }
    else {
      return (
        <div></div>
      )
    }
  }

  function onDDLChange(event) {
    const value = event.target.value
    const go = () => {
      setDirty(false)
      setID(value)
    }
    if (value === "a" || value === "b" || value === "0" || value.startsWith("=")) {
      return
    }
    if (!isCustom() || !dirty) {
      go()
      return
    }
    Swal.fire({
      text: "Load another topology, without saving?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, continue loading.'
    }).then((result) => {
      if (result.isConfirmed) {
        go()
        return
      }
    })
  }

  // function onDDLChange(event) {
  //   const value = event.target.value
  //   const go = () => {
  //     console.log("onDDLChange", "go")
  //     setDirty(false)
  //     setID(value)
  //   }
  //   console.log("onDDLChange", value, dirty)
  //   if (value === "a" || value === "b" || value === "0") {
  //     console.log("onDDLChange", "exiting on check")
  //     return
  //   }
  //   if (isCustom() && dirty) {
  //     Swal.fire({
  //       text: "Load another topology, without saving?",
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       confirmButtonText: 'Yes, continue loading.'
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         go()
  //       }
  //     })
  //   }
  //   else {
  //     console.log("!isCustom() || !dirty", isCustom(), dirty)
  //     go()
  //   }
  //   go()
  // }

  function categorize($data) {
    function create(code) {
      const parts = code.split(".")
      const first = parts.shift()
      const sub = parts.join(".")
      // console.log("parts", parts, "first", first, "sub", sub)
      if (parts.length > 0) {
        // console.log("  sub", { [parts[0]]: {} })
        const results = { [first]: create(sub) }
        // console.log("results", results)
        return results
      }
      else {
        // console.log("  creating", { [code]: {} })
        return { [code]: [] }
      }
    }
    function isObject(item) {
      return (item && typeof item === 'object' && !Array.isArray(item) && item !== null)
    }
    function mergeDeep(target, source) {
      if (isObject(target) && isObject(source)) {
        for (const key in source) {
          if (isObject(source[key])) {
            if (!target[key]) Object.assign(target, { [key]: {} })
            mergeDeep(target[key], source[key])
          }
          else {
            Object.assign(target, { [key]: source[key] })
          }
        }
      }
      return target;
    }
    const pad = (level) => {
      let str = ''
      for (let n = 0; n < level; n++) {
        str += '  '
      }
      return str
    }
    function $getFromMap($map, results, level = 0, code = "") {
      for (const segment in $map) {
        const $code = code.length > 0 ? code + "." + segment : segment
        // console.log("segment", segment)
        if (Array.isArray($map[segment]) === true) {
          results.push({ code: $code, title: pad(level) + $code, item: $data.find(p => p.name === $code) })
        }
        else {
          $getFromMap($map[segment], results, level + 1, $code)
          results.push({ code: $code, title: pad(level) + $code })
        }
      }
    }
    let $map = {}
    const untitled = $data.filter(p => p.name === "uncategorized.untitled")
    let titled = $data.filter(p => p.name !== "uncategorized.untitled")
    titled = titled.sort((a, b) => (a["name"] > b["name"]) ? 1 : -1)
    for (const item of titled) {
      $map = mergeDeep($map, create(item.name))
    }
    const results = []
    $getFromMap($map, results)
    const $results = results.sort((a, b) => (a["code"] > b["code"]) ? 1 : -1)
    for (const item of untitled) {
      $results.push({ code: item.id, title: item.id, item })
    }
    return $results
  }

  function TopologyDropDownList({ onChange }) {
    console.log("getTemplatesElements", "id", id)
    const options = []
    const $templates = categorize(templates)
    // console.log("$templates", $templates)
    for (const p of $templates) {
      if (p.item) {
        options.push(<option key={p.item.id} value={p.item.id}>{p.title}</option>)
      }
      else {
        options.push(<option key={"=" + uuidv4()} value={"=" + uuidv4()}>{p.title}</option>)
      }
    }
    // if (customTemplates) {
    //   // const data = [
    //   //   { "name": "a.b.c", "type": "credit", "id": "1" },
    //   //   { "name": "a.b.d", "type": "savings", "id": "2" },
    //   //   { "name": "a.a.c", "type": "savings", "id": "3" },
    //   //   { "name": "b.b.c", "type": "savings", "id": "4" },
    //   //   { "name": "b.c.g", "type": "savings", "id": "4" },
    //   //   { "name": "a.b.f", "type": "savings", "id": "5" },
    //   //   { "name": "a.b.g", "type": "savings", "id": "6" }
    //   // ]
    //   const $customTemplates = categorize(customTemplates)
    //   // console.log("$customTemplates", $customTemplates)
    //   options.push(<option key="a" value="a">------------------</option>)
    //   for (const p of $customTemplates) {
    //     if (p.item) {
    //       options.push(<option key={p.item.id} value={p.item.id}>{p.title}</option>)
    //     }
    //     else {
    //       options.push(<option key={"=" + uuidv4()} value={"=" + uuidv4()}>{p.title}</option>)
    //     }
    //   }
    //   // for (const option of customTemplates.map(p => <option key={p.id} value={p.id}>{p.name}</option>)) {
    //   //   options.push(option)
    //   // }
    // }
    // else if (isAuthenticated) {
    //   options.splice(0, 0, <option key="b" value="b">No saved topologies</option>)
    // }
    options.splice(0, 0, <option key="0" value="0">Select a topology</option>)
    // console.log("~getTemplatesElements", "id", id)
    return (
      <select value={id} onChange={onDDLChange} className="pure-input-1">
        {options}
      </select>
    )
  }

  async function loadTopology() {
    console.log("loadTopology", "id", id)
    // setID($id)
    // setName('')
    // if ($id === "2") {
    //   ensureId()
    // }
    // else {
    //   setID($id)
    // }
    // if (id === "2") {
    //   setTopology('')
    //   return
    // }
    if (!id || id === "-1" || id === "0" || id === "1" || id.startsWith("=")) {
      return
    }
    let item
    if (false && isCustom() === true && isAuthenticated) {
      // console.log("custom")
      // item = await fetchTopology(id)
    }
    else {
      console.log("not custom")
      item = templates.find(p => p.id === id)
    }
    // console.log("loadTopology", "item", item)
    if (item) {
      // setTemplateConfigValue(id)
      setTopology(item.topology)
      setName(item.name)
    }
    else {
      // console.log("loadTopology", "NOT FOUND", id)
      Swal.fire({
        icon: 'error',
        title: 'Not found.',
        text: 'Topology not found.'
      })
    }
  }

  // function ensureId() {
  //   console.log("ensureId", id)
  //   setID(uuidv4())
  // }

  function onConfigChange(event) {
    console.log("onConfigChange", id)
    const item = templates.find(p => p.id === id)
    // console.log("onConfigChange", "item", item)
    if (item) {
      // setTemplateConfigValue(id)
      setName('')
    }
    setTopology(event.target.value)
  }

  function Config() {
    const $config = generate(topology, parsedData, { addLabHelps, addRestconf, addOspfP2p, addOspfArea0, addStub, addAAA, iosVersion, addManagedSwitchConfig })
    return (
      <pre className="link" onClick={() => copyToClipboard($config)}>
        {$config}
      </pre>)
  }

  return (
    <form className="pure-form" onSubmit={handleSubmit}>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1-3">
          {/* <TemplateConfig templateConfigValue={templateConfigValue} handleChange={() => dispatch({ "type": "TemplateConfig" })} /> */}
          <TopologyDropDownList />
          <textarea id="topology" name="topology" className={getInputClassName()} value={topology} onChange={e => { setDirty(true); onConfigChange(e) }} />
          {
            !isAuthenticated
              ? <Fragment></Fragment>
              :
              <Fragment>
                {/* <p>[ID:{id}]</p>
                <p>[dirty:{dirty.toString()}]</p>
                <p>[name:{name}]</p> */}
                {/* <input id="name" name="name" className={getInputClassName()} disabled={isCustom() ? "" : "disabled"} value={name} onChange={e => { setDirty(true); setName(e.target.value) }} /> */}
                {/* <button className="pure-button" onClick={() => newTopology()} >New</button> */}
                <button className="pure-button" onClick={() => exportConfig()} >Export</button>
              </Fragment>
          }
          {
            !isAuthenticated
              ? <Fragment></Fragment>
              :
              <Fragment>
                {!isCustom() ?
                  <Fragment></Fragment> :
                  <Fragment>
                    <button className="pure-button" onClick={() => saveTopology()}>Save</button>
                    <button className="pure-button" onClick={() => deleteTopology()}>Delete</button>
                  </Fragment>}
              </Fragment>
          }
          <div className="pure-g">
            <div className="pure-u-1-2">
              <fieldset>
                <label htmlFor="addLabHelps" className="pure-checkbox"><input id="addLabHelps" name="addLabHelps" type="checkbox" checked={addLabHelps} onChange={e => setAddGeneric(e.target.checked)} /> Lab helps</label>
                <label htmlFor="addAAA" className="pure-checkbox"><input id="addAAA" name="addAAA" type="checkbox" checked={addAAA} onChange={e => setAddAAA(e.target.checked)} /> Add AAA</label>
                <label htmlFor="addStub" className="pure-checkbox"><input id="addStub" name="addStub" type="checkbox" checked={addStub} onChange={e => setAddStub(e.target.checked)} /> Add Stub</label>
                <label htmlFor="addOspfP2p" className="pure-checkbox"><input id="addOspfP2p" name="addOspfP2p" type="checkbox" checked={addOspfP2p} onChange={e => setAddOspfP2p(e.target.checked)} /> OSPF P2P</label>
                <label htmlFor="addOspfArea0" className="pure-checkbox"><input id="addOspfArea0" name="addOspfArea0" type="checkbox" checked={addOspfArea0} onChange={e => setAddOspfArea0(e.target.checked)} /> OSPF Area 0</label>
                <label htmlFor="addRestconf" className="pure-checkbox"><input id="addRestconf" name="addRestconf" type="checkbox" checked={addRestconf} onChange={e => setAddRestconf(e.target.checked)} /> RESTCONF</label>
                <label htmlFor="addManagedSwitchConfig" className="pure-checkbox"><input id="addManagedSwitchConfig" name="addManagedSwitchConfig" type="checkbox" checked={addManagedSwitchConfig} onChange={e => setAddManagedSwitchConfig(e.target.checked)} /> Using Managed Switch</label>
              </fieldset>
            </div>
            <div className="pure-u-1-2">
              <IosVersion iosVersion={iosVersion} setIosVersion={setIosVersion} />
            </div>
          </div>
          <p>Devices/links: <span>{countDevices(parsedData)}</span>/<span>{countLinks(parsedData)}</span></p>
        </div>
        <div className="pure-u-1 pure-u-md-2-3">{getGraphElement()}</div>
      </div>
      <Config />
    </form>
  )
}

export default Form