import React, { useEffect } from "react"

export function IosVersion({ iosVersion, setIosVersion }) {
  useEffect(() => {
    console.log("iosVersion", iosVersion)
  }, [iosVersion])

  return (
    <fieldset>
      <label htmlFor="g2" className="pure-radio">
        <input type="radio" id="g2" name="iosVersion" checked={iosVersion === "g2"} value="g2" onChange={ev => setIosVersion(ev.target.value)} /> VIRL CSR (g2)
        </label>
      <label htmlFor="g00" className="pure-radio">
        <input type="radio" id="g00" name="iosVersion" checked={iosVersion === "g0/0"} value="g0/0" onChange={ev => setIosVersion(ev.target.value)} /> g0/0
        </label>
      <label htmlFor="g01" className="pure-radio">
        <input type="radio" id="g01" name="iosVersion" checked={iosVersion === "g0/1"} value="g0/1" onChange={ev => setIosVersion(ev.target.value)} /> g0/1
        </label>
      <label htmlFor="g0" className="pure-radio">
        <input type="radio" id="g0" name="iosVersion" checked={iosVersion === "g0"} value="g0" onChange={ev => setIosVersion(ev.target.value)} /> g0
        </label>
      <label htmlFor="g1" className="pure-radio">
        <input type="radio" id="g1" name="iosVersion" checked={iosVersion === "g1"} value="g1" onChange={ev => setIosVersion(ev.target.value)} /> g1
        </label>
    </fieldset>
  )
}
