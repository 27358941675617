import { Commands } from './Commands'

const preambleTemplate = `
!! {{ Name }}
conf t
hostname {{ Name }}`
const aaaTemplate = `username cisco priv 15 secret cisco
aaa new-model
aaa authorization exec default local`
const restconfTemplate = `username cisco priv 15 secret cisco
aaa new-model
aaa authorization exec default local 
restconf
ip http authentication local
ip http secure-server`

function createLoopback(device, options) {
    const template = loadCommands(options, device.type, "loopback")
    return template.replace(/{{ Id }}/g, device.id).replace(/{{ OspfArea0 }}/g, options.addOspfArea0 === true ? "\n ip ospf 1 area 0" : "")
}

function createStub(device, options) {
    const template = loadCommands(options, device.type, "stub")
    return template.replace(/{{ Interface }}/g, options.iosVersion).replace(/{{ Id }}/g, device.id).replace(/{{ Segment }}/g, parseInt(device.id) * 10).replace(/{{ OspfArea0 }}/g, options.addOspfArea0 === true ? "\n ip ospf 1 area 0" : "")
}

function createPreamble(device, options) {
    let preamble = preambleTemplate.replace(/{{ Name }}/g, device.name)
    if (options.addLabHelps === true) {
        const template = loadCommands(options, device.type, "generic")
        preamble = preamble + '\n' + template
    }
    return preamble
}

function createSubinterfaces(device, options) {
    const output = []
    device.links.sort((a, b) => {
        if (a['id'] < b['id'])
            return -1
        if (a['id'] > b['id'])
            return 1
        return 0
    })
    const vlans = []
    for (const link of device.links.filter(p => parseInt(p.vlan) < 4095)) {
        const subinterface = link.vlan
        const vlan = link.vlan
        const current = device.id
        let a = device.id
        let b = link.id
        let ospfP2p = ''
        let ospfArea0 = ''
        let ospfEnd = ''
        if (device.type === 'r') {
            if (options.addOspfP2p === true && link.type !== "v") {
                ospfP2p = '\n ip ospf network point-to-point'
            }
            if (options.addOspfArea0 === true) {
                ospfArea0 = '\n ip ospf 1 area 0'
            }
            if (options.addOspfP2p !== true || options.addOspfArea0 !== true) {
                ospfEnd = '\n'
            }
        }
        if (link.type === 'v') {
            a = link.vlan
            b = link.vlan
        }
        else if (a > b) {
            [a, b] = [b, a]
        }
        if (a > 4094 || b > 4094) {
            continue
        }
        const template = loadCommands(options, device.type, "interface")
        const si = template
            .replace(/{{ Interface }}/g, options.iosVersion)
            .replace(/{{ Subinterface }}/g, subinterface)
            .replace(/{{ Vlan }}/g, vlan)
            .replace(/{{ From }}/g, a)
            .replace(/{{ To }}/g, b)
            .replace(/{{ OspfP2p }}/g, ospfP2p)
            .replace(/{{ OspfArea0 }}/g, ospfArea0 + ospfEnd)
            .replace(/{{ Current }}/g, current)
        output.push(si)
        vlans.push(vlan)
    }
    return {
        subinterfaces: output.join('\n'),
        vlans
    }
}

function createBareSubinterfaces(device, options) {
    const output = []
    device.links.sort((a, b) => {
        if (a['id'] < b['id'])
            return -1
        if (a['id'] > b['id'])
            return 1
        return 0
    })
    const vlans = []
    if (options.addStub === true) {
        const stubTemplate = loadCommands(options, device.type, "bareStub")
        const stub = stubTemplate
            .replace(/{{ Interface }}/g, options.iosVersion)
            .replace(/{{ Id }}/g, device.id)
        output.push(stub)
        vlans.push(device.id)
    }
    for (const link of device.links.filter(p => parseInt(p.vlan) < 4095)) {
        const subinterface = link.vlan
        const vlan = link.vlan
        let a = device.id
        let b = link.id
        if (a > 4094 || b > 4094) {
            continue
        }
        const template = loadCommands(options, device.type, "bareInterface")
        const si = template
            .replace(/{{ Interface }}/g, options.iosVersion)
            .replace(/{{ Subinterface }}/g, subinterface)
        output.push(si)
        vlans.push(vlan)
    }
    return output.join('\n')
}


function loadCommands(options, deviceType, templateName) {
    // console.log("loadCommands", deviceType, templateName)
    let template = Commands[deviceType][templateName]
    if (!template) {
        console.log(`${templateName} for ${deviceType} not found, using CSR1000v`)
        template = Commands["r"][templateName]
    }
    return template
}

function createEnabler(device, options) {
    const template = loadCommands(options, device.type, "enabler")
    return template.replace(/{{ Interface }}/g, options.iosVersion)
}

function createRestconf(device, options) {
    if (options.iosVersion === 'iosv' || device.type === 'xr') {
        return ''
    }
    if (options.addRestconf === true) {
        return restconfTemplate
    }
    return ''
}

function generate(topology, devices, options = {}) {
    if (!topology || topology.length === 0) {
        return ''
    }
    // console.log("generate", "len(config)", config.length, "devices", devices, options)
    if (!devices) {
        return ''
    }
    const output = []
    const vlans = []
    const ids = Object.keys(devices).map(p => parseInt(p))
    ids.sort((a, b) => {
        if (a < b)
            return -1
        if (a > b)
            return 1
        return 0
    })
    const bareConfigs = []
    for (const id of ids) {
        const bareInterfaces = []
        const device = devices[id]
        if (device.type === 'v') {
            vlans.push(id)
            continue
        }
        if (device.type === 'g') {
            continue
        }
        const preamble = createPreamble(device, options) + '\n'
        let loopback = createLoopback(device, options) + '\n'
        let stub = ''
        if (options.addStub === true) {
            stub = createStub(device, options) + '\n'
            vlans.push(id)
        }
        let enabler = ''
        let subinterfaces = ''
        let restconf = ''
        let aaa = ''

        if (device.type !== 's' && device.type !== 'nx') {
            const bareinterface = createBareSubinterfaces(device, options)
            bareInterfaces.push(bareinterface)
            const ret = createSubinterfaces(device, options)
            subinterfaces = ret.subinterfaces + '\n'
            for (const vlan of ret.vlans) {
                vlans.push(vlan)
            }
            restconf = '\n' + createRestconf(device, options) + '\n'
            if (options.addRestconf === false && options.addAAA === true) {
                aaa = '\n' + aaaTemplate + '\n'
            }
            enabler = createEnabler(device, options)
        }

        bareConfigs.push("\n!! " + device.name + "\nconf t\n" + bareInterfaces.join("\n") + "\nend\n")

        output.push(preamble + loopback + stub + subinterfaces + aaa + restconf + enabler + '\n\nend\n\n')
    }
    if (output.length === 0) {
        return "No topology."
    }
    let vlanConfig = ''
    if (options.addManagedSwitchConfig === true) {
        let helps = '\n'
        if (options.addLabHelps === true) {
            helps = Commands.r.generic
        }
        console.log("len(devices)", devices.length)
        const interfaces = []
        for (let n = 0; n < 4; n++) {
            for (let m = 0; m < 4; m++) {
                interfaces.push(`g${n}/${m}`)
            }
        }
        const trunkConfig = interfaces.map(p => "int " + p + "\nsw tr en dot1q\nsw mo tr").join("\n")
        vlanConfig = "! managed switch config\n! paste into a managed switch to create vlans and setup dot1q\n\nconf t" + helps + "\n" + trunkConfig + "\n" + [...new Set(vlans)].map(p => parseInt(p)).filter(p => p < 4095).sort().map(p => "vlan " + p).join("\n") + "\nexit\n\n"
    }
    const bareConfig = "\n!!! reference\n" + bareConfigs.join("\n")
    const result = vlanConfig + '!!! topology\n\n' + topology.split("\n").map(p => p).join("\n") + "\n\n!!! addressing\n" + output.join('\n\n') + bareConfig
    // console.log(result)
    return result
}

export { generate }
